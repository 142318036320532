<template>
    <v-menu
        ref="menu"
        v-model="menu"
        class="range-datepicker__menu-block"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y>
        <template #activator="{ on }">
            <v-text-field
                class="range-datepicker-field"
                readonly
                outlined
                :value="dateRangeText"
                :label="label"
                v-on="on">
                <template #prepend>
                    <img
                        src="../../assets/Calendar3.svg"
                        class="prepend-icon" />
                </template>
                <template #append>
                    <img src="../../assets/DownArrow2.svg" class="range-datepicker__append" />
                </template>
            </v-text-field>
            <div class="v-messages error--text" role="alert">
                <div class="v-messages__wrapper">
                    <div class="v-messages__message">{{ errorMessages }}</div>
                </div>
            </div>
        </template>
        <v-date-picker
            :value="value"
            :first-day-of-week="1"
            range
            no-title
            color="#E61E4D"
            :locale="lang === 'ru' ? 'ru-RU' : 'en-US'"
            :type="type"
            @input="updateDate" />
    </v-menu>
</template>

<script>
    import { mapState } from 'vuex';
    import moment from '@/plugins/moment'

    export default {
        props: {
            value: {
                type: Array,
                default: () => { [] },
            },
            label: {
                type: String,
                default: '',
            },
            errorMessages: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'date',
            }
        },
        data: () => ({
            menu: false,
            modal: false,
            menu2: false,
        }),
        computed: {
            ...mapState('settings', ['lang']),
            dateRangeText() {
                if (this.value.length !== 0) {
                    let newValue = [];
                    if (this.value[0]) {
                        newValue.push(moment(this.value[0]).format('MMMM YY'));
                    }
                    if (this.value[1]) {
                        newValue.push(moment(this.value[1]).format('MMMM YY'));
                    }
                    // return 'с ' + newValue.join(' по ');
                    if (newValue[0] !== newValue[1]) {
                        return newValue.join(' - ');
                    } else {
                        return newValue[0]
                    }
                } 
                return '';
            }
        },
        methods: {
            updateDate(value) {
                this.$emit('change', value);
                if (value.length > 1 ) {
                    this.menu = false;
                }
            },
        },
    };
</script>

<style lang="scss">
.range-datepicker-field {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #273155 !important;
    .v-menu__content {
        display: flex;
        justify-content: center;
    }
    .v-input__slot {
        border-radius: 10px;
        background: #fff !important;
        box-shadow: rgba(30, 28, 46, 0.03);
        margin: 0;
        height: 44px;
    }
    .v-label.theme--light {
        @media all and (max-width: 768px) {
            top: 12px;
        }
    }
    input {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155 !important;
        // width: 100%;
        cursor: pointer;
    }
    &.v-text-field--outlined.v-input--is-focused fieldset,
    &.v-text-field--outlined.v-input--has-state fieldset {
        border: 1px solid #EBEBEB !important;
    }
    .prepend-icon {
        position: absolute;
        left: 16px;
        top: 12px;
        z-index: 1;
        @media all and (min-width: 768px) {
            top: 18px;
        }
    }
    .v-input__prepend-outer {
        width: 0;
        margin: 14px 0 0 0 !important;
    }
    .range-datepicker__append {
        position: absolute;
        top: 18px;
        right: 20px;
        @media all and (min-width: 768px) {
            top: 25px;
        }
    }
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 44px;
        max-height: 56px;
        height: auto;
    }
    &.theme--light.v-label {
        left: 20px;
    }
    &.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #fff;
        border: 1px solid #EBEBEB;
        }

    &.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 44px;
        @media all and (min-width: 768px) {
            min-height: 56px;
        }
    }

    .v-messages {
        flex: 1 1 auto;
        min-height: 0;
        min-width: 0;
        position: relative;
    }
    &.v-text-field.v-text-field--enclosed .v-text-field__details {
        min-height: 0;
        margin-bottom: 0;
    }

    .v-text-field__slot {
        padding: 0 0 0 28px;
        width: 100%;
        
    }
    .v-label.theme--light {
        left: 30px !important;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155 !important;
        &.v-label--active {
            display: none;
        }
    }
}

.v-picker__body {
    width: 100%;
    display: flex;
}
</style>

